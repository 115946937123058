import LayoutHeaderFooter from '@components/layout-header-footer';
import { Tab } from '@components/router-tabs';
import React from 'react';
import { RouterPaths } from '../types/routers';

const edgeTabs: Tab[] = [
  {
    key: 'price',
    label: 'Price Feeds',
    to: RouterPaths.PriceFeeds,
  },
  {
    key: 'risk',
    label: 'Risk feeds',
    to: RouterPaths.RiskFeeds,
  },
  {
    key: 'proofs',
    label: 'PoR feeds',
    disabled: true,
  },
];

export const EdgeLayout = () => <LayoutHeaderFooter tabs={edgeTabs} />;
