export const RouterParams = {
  FeedId: ':id',
  ClientId: ':clientId',
  ParameterName: ':parameterName',
  Client: ':client',
  MarketId: ':marketId',
  Chain: ':chain',
  AssetName: ':assetName',
  PoolName: ':poolName',
  ReferenceId: ':referenceId',
};

export const RouterPaths = {
  Landing: '/home',
  AuthCallback: '/auth/callback',
  PriceFeeds: '/price-feeds',
  RiskFeeds: '/risk-feeds',
  PriceFeedDetails: `/price-feeds/${RouterParams.FeedId}`,
  RiskFeedDetails: `/risk-feeds/${RouterParams.ClientId}`,
  Oracle: `/oracle`, // For sub-router, append '/*' in Route to="..."
};

export const InternalToolPaths = {
  Overview: '/internal-tool/overview',
  OverviewParameterDrillDown: '/internal-tool/overview/drill-down',
  EvaluationParameterDrillDown: '/internal-tool/overview/evaluation',
  ApprovalHub: '/internal-tool/approval-hub',
  RecommendationsSettings: '/internal-tool/recommendations-settings',
};
