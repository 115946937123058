import AeonikFonoBoldOtf from '@assets/fonts/aeonik-fono-bold.otf';

import AtAeroWoff2 from '@assets/fonts/at-aero-medium.woff2';
import Icon from '@components/icon';
import { alpha, createTheme, darken, getContrastRatio, Theme } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

type ProductType = 'edge' | 'ai';
declare module '@mui/material/styles' {
  interface Theme {
    watermark: ProductType;
  }
  interface Palette {
    bg: {
      paper: string; // Bg/Default/UI background solid
      paperOpacity: string; // Bg/Default/UI background solid
      paperHover: string; // Bg/Default/Default Hover
      secondary: string; // Bg/Default/Secondary
      secondaryOpacity: string; // Bg/Default/Secondary
      tertiary: string; // Bg/Default/Tertiary
      tertiaryHover: string; // Bg/Default/Tertiary Hover
      positive: string; // Bg/Default/Positive
      tooltip: string; // Bg/Default/Tooltip bg
      tooltipHover: string; // Bg/Default/Tooltip bg
      buttonSelected: string; // Bg/Default/Selected button
      warning: string; // Bg/Default/Warning
    };
    txt: {
      ui: string; // Text/Default/UI
      tertiary: string; // Text/Default/Tertiary
      positive: string; // Text/Positive/Secondary
      secondaryPositive: string; // Text/Positive/Tertiary
      onLayout: string; // Text/Chatbox/Default
      active: string; // Text/Chatbox/Active
      code: string; // Text/Utilities/Code
      tooltip: string; // Text/Utilities/Tooltip
    };
    border: {
      default: string; // Border/Default/Chatbox Default
      secondary: string; // Border/Default/Secondary
      tertiary: string; // Border/Default/Tertiary
      positive: string; // Border/Positive/Secondary
      secondaryPositive: string; // Border/Positive/Tertiary
      swatch: string; // Border/Utilities/Swatch
    };
    icon: {
      default: string; // Icon/Default/Default
      secondary: string; // Icon/Default/Secondary
      tertiary: string; // Icon/Default/Tertiary
      positive: string; // Icon/Positive/Secondary
      secondaryPositive: string; // Icon/Positive/Tertiary
    };
    textSelection: string;
    brandColor: string;
    buttonShadow: string;
  }

  interface PaletteOptions {
    bg: {
      paper: string; // Bg/Default/UI background solid
      paperOpacity: string; // Bg/Default/UI background solid
      paperHover: string; // Bg/Default/Default Hover
      secondary: string; // Bg/Default/Secondary
      secondaryOpacity: string; // Bg/Default/Secondary
      tertiary: string; // Bg/Default/Tertiary
      tertiaryHover: string; // Bg/Default/Tertiary Hover
      positive: string; // Bg/Default/Positive
      tooltip: string; // Bg/Default/Tooltip bg
      tooltipHover: string; // Bg/Default/Tooltip bg
      buttonSelected: string; // Bg/Default/Selected button
    };
    txt: {
      ui: string; // Text/Default/UI
      tertiary: string; // Text/Default/Tertiary
      positive: string; // Text/Positive/Secondary
      secondaryPositive: string; // Text/Positive/Tertiary
      onLayout: string; // Text/Chatbox/Default
      active: string; // Text/Chatbox/Active
      code: string; // Text/Utilities/Code
      tooltip: string; // Text/Utilities/Tooltip
    };
    border: {
      default: string; // Border/Default/Chatbox Default
      secondary: string; // Border/Default/Secondary
      tertiary: string; // Border/Default/Tertiary
      positive: string; // Border/Positive/Secondary
      secondaryPositive: string; // Border/Positive/Tertiary
      swatch: string; // Border/Utilities/Swatch
    };
    icon: {
      default: string; // Icon/Default/Default
      secondary: string; // Icon/Default/Secondary
      tertiary: string; // Icon/Default/Tertiary
      positive: string; // Icon/Positive/Secondary
      secondaryPositive: string; // Icon/Positive/Tertiary
    };
    textSelection: string;
    brandColor: string;
    buttonShadow: string;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    label: true;
    link: true;
    paragraph: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    stroke: true;
    default: true;
    neutral: true;
    subtle: true;
  }
  interface ButtonPropsSizeOverrides {
    tiny: true;
  }

  interface ButtonClasses {
    sizeTiny: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  label: React.CSSProperties;
  link: React.CSSProperties;
}

function getContrastText(backgroundColor: string) {
  const blackContrast = getContrastRatio(backgroundColor, '#000000');
  const whiteContrast = getContrastRatio(backgroundColor, '#FFFFFF');

  // Return the color with the highest contrast ratio
  return blackContrast >= whiteContrast ? '#000000' : '#FFFFFF';
}

const fontSizes = {
  h1: 67,
  h2: 32,
  h3: 26,
  h4: 14,
  h5: 14,
  h6: 14,
  paragraph: 16,
  header: 14,
  button: 11,
  body1: 13, // body
  body2: 11, // Body small
  caption: 11, // Captions and code
};

const colors = {
  brandColor: '#7DE005',
  brandColorAi: '#2D6AE7',
  red: {
    ['100']: '#D32F2F',
    ['200']: '#FF5252',
    ['300']: '#E2483D80',
  },
  green: {
    ['100']: '#CAFF8A',
    ['150']: '#A3F047',
    ['200']: '#7DE005',
    ['300']: '#569905',
    ['400']: '#14AE5C',
    ['500']: '#2FC77E',
    opacity: {
      ['200']: '#7DE0058A',
    },
  },
  blue: {
    ['100']: '#2D6AE7',
    ['150']: '#2D6AE7',
    ['200']: '#2D6AE7',
    ['300']: '#2D6AE7',
    opacity: {
      ['200']: '#2D6AE78A',
    },
  },
  grey: {
    ['0']: '#FFFFFF',
    ['1']: '#F6F8F9',
    ['2']: '#EFF2F3',
    ['3']: '#D4E0E7',
    ['4']: '#B4C6CD',
    ['5']: '#98A2B3',
    ['6']: '#597182',
    ['7']: '#556A87',
    ['8']: '#384B65',
    ['9']: '#25354B',
    ['700']: '#1E273D',
    ['800']: '#131C31',
    ['900']: '#0D1425',
    ['950']: '#0B101C',
    opacity: {
      ['0']: '#65899E0F',
      ['1']: '#65899E14',
      ['2']: '#65829E1A',
      ['3']: '#65899E30',
    },
  },
};

function parseHex(hex: string) {
  // Remove the '#' if present
  hex = hex.replace('#', '');

  // Parse the hex string into red, green, blue components
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  const a = parseInt(hex.substring(6, 8) ?? 'FF', 16);

  return [r, g, b, a];
}

function rgbToHex(r: number, g: number, b: number) {
  return `#${[r, g, b]
    .map((x) => x.toString(16).padStart(2, '0'))
    .join('')
    .toUpperCase()}`;
}

function blendColors(background: string, overlay: string) {
  // Parse background and overlay colors (in hex format)
  const bg = parseHex(background);
  const ov = parseHex(overlay);
  const alpha = ov[3] / 255;

  // Calculate the resulting color components
  const r = Math.round(ov[0] * alpha + bg[0] * (1 - alpha));
  const g = Math.round(ov[1] * alpha + bg[1] * (1 - alpha));
  const b = Math.round(ov[2] * alpha + bg[2] * (1 - alpha));

  // Return the result in hex format
  return rgbToHex(r, g, b);
}

const getThemePalette = (mode: 'light' | 'dark', product: ProductType) => {
  const isLight = mode === 'light';
  const isAiProduct = product === 'ai';

  const primaryColorScheme = isAiProduct ? colors.blue : colors.green;
  const backgroundDefault = isLight ? colors.grey['0'] : colors.grey['950'];

  return {
    watermark: product,
    palette: {
      primary: {
        main: isAiProduct ? primaryColorScheme['150'] : primaryColorScheme['200'],
      },
      secondary: {
        main: isAiProduct ? primaryColorScheme['200'] : primaryColorScheme['150'],
      },
      success: {
        main: isAiProduct
          ? isLight
            ? colors.green['400']
            : colors.green['500']
          : colors.green['200'],
      },
      error: {
        main: '#FF5B87',
      },
      info: {
        main: '#436FC4',
      },
      background: {
        default: backgroundDefault, // Bg/Default/Default
        paper: isLight ? colors.grey['1'] : colors.grey['900'],
      },
      text: {
        primary: isLight ? colors.grey['8'] : colors.grey['0'], // Text/Default/Primary
        secondary: colors.grey['5'], // Text/Default/Secondary
        disabled: isLight ? colors.grey['3'] : alpha(colors.grey['0'], 0.2), // Text/Chatbox/Disabled
      },
      brandColor: isAiProduct ? colors.brandColorAi : colors.brandColor,
      textSelection: isLight
        ? alpha(primaryColorScheme['150'], 0.2)
        : alpha(primaryColorScheme['300'], 0.6),
      divider: blendColors(backgroundDefault, colors.grey.opacity['3']), // Border/Default/Default
      bg: {
        paper: blendColors(backgroundDefault, colors.grey.opacity['0']), // Bg/Default/UI background solid
        paperOpacity: colors.grey.opacity['0'], // Bg/Default/UI background solid
        paperHover: blendColors(backgroundDefault, colors.grey.opacity['1']), // Bg/Default/Default Hover
        secondary: blendColors(backgroundDefault, colors.grey.opacity['2']), // Bg/Default/Secondary
        secondaryOpacity: colors.grey.opacity['2'], // Bg/Default/Secondary
        tertiary: isLight ? colors.grey['3'] : colors.grey['8'], // Bg/Default/Tertiary
        tertiaryHover: isLight ? colors.grey['7'] : colors.grey['700'], // Bg/Default/Tertiary Hover
        positive: blendColors(backgroundDefault, primaryColorScheme.opacity['200']), // Bg/Default/Positive
        tooltip: isLight ? colors.grey['6'] : colors.grey['3'], // Bg/Default/Tooltip bg
        tooltipHover: isLight ? colors.grey['5'] : colors.grey['4'], // Bg/Default/Tooltip bg hover
        buttonSelected: isLight ? colors.grey['6'] : colors.grey['8'], // Bg/Default/Selected button
        warning: isLight ? colors.red['100'] : colors.red['300'], // Bg/Default/Warning
        error: isLight ? colors.red['200'] : colors.red['100'], // Bg/Default/Error
      },
      txt: {
        ui: isLight ? colors.grey['7'] : colors.grey['4'], // Text/Default/UI
        tertiary: colors.grey['6'], // Text/Default/Tertiary
        positive: colors.green['200'], // Text/Positive/Secondary
        secondaryPositive: isLight ? colors.green['300'] : colors.green['200'], // Text/Positive/Tertiary
        onLayout: isLight ? colors.grey['5'] : '#FFFFFFB2', // Text/Chatbox/Default
        active: isLight ? colors.grey['7'] : colors.grey['0'], // Text/Chatbox/Active
        code: isLight ? colors.red['100'] : colors.red['200'], // Text/Utilities/Code
        tooltip: isLight ? colors.grey['3'] : colors.grey['8'], // Text/Utilities/Tooltip
      },
      border: {
        default: isLight ? colors.grey['3'] : colors.grey['6'], // Border/Default/Chatbox Default
        secondary: isLight ? colors.grey['2'] : colors.grey['5'], // Border/Default/Secondary
        tertiary: colors.grey['4'], // Border/Default/Tertiary
        positive: colors.green['200'], // Border/Positive/Secondary
        secondaryPositive: colors.green['200'], // Border/Positive/Tertiary
        swatch: isLight ? '#0000003D' : '#FFFFFF15', // Border/Utilities/Swatch
      },
      icon: {
        default: isLight ? colors.grey['4'] : colors.grey['6'], // Icon/Default/Default
        secondary: isLight ? colors.grey['5'] : colors.grey['6'], // Icon/Default/Secondary
        tertiary: isLight ? colors.grey['4'] : colors.grey['6'], // Icon/Default/Tertiary
        positive: colors.green['200'], // Icon/Positive/Secondary
        secondaryPositive: colors.green['200'], // Icon/Positive/Tertiary
      },
      buttonShadow: '0px 2px 10px 0px rgba(91, 96, 111, 0.25)',
    },
  };
};

const getTheme = (mode: 'light' | 'dark', product: ProductType = 'edge') => {
  const themePalette = getThemePalette(mode, product);

  return createTheme({
    ...themePalette,
    typography: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      textWrap: 'pretty',
      h1: {
        fontFamily: 'At Aero',
        fontWeight: 500,
        fontSize: `${fontSizes.h1}px`,
        lineHeight: 1.2,
        letterSpacing: -0.03,
      },
      h2: {
        fontFamily: 'At Aero',
        fontWeight: 500,
        fontSize: `${fontSizes.h2}px`,
        lineHeight: 1.23,
        letterSpacing: -0.03,
      },
      h3: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: `${fontSizes.h3}px`,
        lineHeight: 1.2,
        letterSpacing: -0.01,
      },
      h4: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: `${fontSizes.h4}px`,
        lineHeight: 1.22,
      },
      h5: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: `${fontSizes.h5}px`,
        lineHeight: 1.2,
      },
      h6: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: `${fontSizes.h6}px`,
        lineHeight: 1.2,
      },
      label: {
        fontFamily: 'Aeonik Fono',
        fontWeight: 500,
        fontSize: `${fontSizes.header}px`,
        lineHeight: 1.4,
        letterSpacing: 0.08,
        textTransform: 'uppercase',
        color: themePalette.palette.text.secondary,
      },
      button: {
        fontFamily: 'Aeonik Fono',
        fontWeight: 500,
        fontSize: `${fontSizes.button}px`,
        lineHeight: 1.4,
        letterSpacing: 0.08,
        textTransform: 'uppercase',
        color: themePalette.palette.text.secondary,
      },
      body1: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: `${fontSizes.body1}px`,
        lineHeight: 1.38,
        letterSpacing: 0.01,
      },
      body2: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: `${fontSizes.body2}px`,
        lineHeight: 1.38,
        letterSpacing: 0.01,
      },
      paragraph: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: `${fontSizes.paragraph}px`,
        lineHeight: 1.4,
      },
      caption: {
        fontFamily: 'Aeonik Fono',
        fontWeight: 500,
        fontSize: `${fontSizes.caption}px`,
        lineHeight: 1.38,
        color: themePalette.palette.text.secondary,
      },
      subtitle1: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: `${fontSizes.header}px`,
        lineHeight: 1.4,
        letterSpacing: 0.08,
        color: themePalette.palette.text.secondary,
      },
      subtitle2: {
        fontFamily: 'Aeonik Fono',
        fontWeight: 500,
        fontSize: `${fontSizes.header}px`,
        lineHeight: 1.4,
        letterSpacing: 0.08,
        color: themePalette.palette.text.secondary,
      },
      link: {
        fontFamily: 'Aeonik Fono',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.14px',
        color: themePalette.palette.text.primary,
        textDecoration: 'none',
        textTransform: 'uppercase',
        '&:hover': {
          color: darken(themePalette.palette.primary.main, 0.1),
        },
        '&:active': {
          color: darken(themePalette.palette.primary.main, 0.2),
        },
        '& svg path': {
          fill: 'currentColor',
        },
      },
    } as ExtendedTypographyOptions,
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: "At Aero";
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local("At Aero"), local("At Aero-Medium"), url(${AtAeroWoff2}) format("woff2");
        }
        @font-face {
          font-family: "Aeonik Fono";
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local("Aeonik Fono"), local("Aeonik Fono-Bold"), url(${AeonikFonoBoldOtf}) format("opentype");
        }
        a:hover {
          text-decoration: none;
        }
        
        ::selection {
          background-color: ${themePalette.palette.textSelection};
        }
    
        /* For Firefox */
        ::-moz-selection {
          background-color: ${themePalette.palette.textSelection};
        }
        
        code {
          background-color: ${themePalette.palette.bg.secondary};
          color: ${themePalette.palette.txt.code};
          padding: 4px;
          border-radius: 4px;
          display: inline-block;
          overflow-x: scroll;
          max-width: 100%;
        }
      `,
      },
      MuiSkeleton: {
        styleOverrides: {
          root: {
            transform: 'scale(1,1)',
            backgroundColor: themePalette.palette.bg.paperHover,
            '&::after': {
              backgroundColor: themePalette.palette.bg.paper,
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableRipple: true,
        },
        variants: [
          {
            props: { variant: 'default' },
            style: {
              backgroundColor: themePalette.palette.bg.paper,
              border: 'none',
              color: themePalette.palette.text.primary,
              borderRadius: 4,
              '&:hover': {
                backgroundColor: themePalette.palette.bg.paperHover,
              },
            },
          },
          {
            props: { variant: 'neutral' },
            style: (th) => {
              const { ownerState } = th as unknown as { ownerState: { color: string } };

              return {
                backgroundColor:
                  ownerState.color === 'success'
                    ? colors.green['500']
                    : ownerState.color === 'error'
                      ? colors.red['100']
                      : themePalette.palette.bg.paperOpacity,
                color:
                  ownerState.color === 'secondary'
                    ? themePalette.palette.primary.main
                    : themePalette.palette.text.primary,
                ...(ownerState.color === 'error' ? {} : { border: '1px solid' }),
                borderColor: themePalette.palette.divider,
                borderRadius: 4,
                '&:hover': {
                  backgroundColor:
                    ownerState.color === 'error'
                      ? colors.red['300']
                      : themePalette.palette.bg.tertiary,
                },
                '&:disabled': {
                  opacity: 0.7,
                  color: themePalette.palette.text.disabled,
                  backgroundColor:
                    ownerState.color === 'success'
                      ? colors.green['500']
                      : ownerState.color === 'error'
                        ? colors.red['100']
                        : themePalette.palette.bg.paperOpacity,
                },
              };
            },
          },
          {
            props: { variant: 'subtle' },
            style: {
              '&:hover': {
                backgroundColor: themePalette.palette.bg.paperHover,
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            fontFamily: 'Aeonik Fono',
            fontSize: 14,
            lineHeight: '24px',
            letterSpacing: '0.14px',
            borderRadius: 2,
            padding: '11px 23px',
            whiteSpace: 'nowrap',
            variants: [
              {
                props: { variant: 'outlined' },
                style: {
                  background: 'transparent',
                  borderColor: themePalette.palette.divider,
                  padding: '11px 23px',

                  '&:hover': {
                    backgroundColor: themePalette.palette.bg.paperHover,
                  },

                  '&:focus': {
                    backgroundColor: themePalette.palette.bg.paperHover,
                  },

                  '&:active': {
                    backgroundColor: themePalette.palette.divider,
                  },

                  '&:disabled': {
                    opacity: 0.5,
                  },

                  '& .MuiButton-startIcon': {
                    marginLeft: 0,
                  },
                },
              },
              {
                props: { variant: 'contained' },
                style: {
                  backgroundColor: themePalette.palette.primary.main,
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: themePalette.palette.secondary.main,
                    boxShadow: 'none',
                  },
                  '&:focus': {
                    outline: '1px solid transparent',
                    outlineColor: themePalette.palette.secondary.main,
                  },
                  '&:disabled': {
                    backgroundColor: 'transparent',
                    border: '1px solid',
                    borderColor: themePalette.palette.border.default,
                    color: themePalette.palette.text.disabled,
                  },
                },
              },
              {
                props: { variant: 'text' },
                style: {
                  '&:focus': {
                    outline: 'none',
                  },
                },
              },
            ],
            '&.Mui-disabled': {
              opacity: 0.5,
              backgroundColor: themePalette.palette.primary.main,
              '.MuiButton-icon': {
                opacity: 0.5,
              },
            },
          },
          sizeSmall: {
            padding: '12px',
            fontSize: fontSizes.body2,
            lineHeight: `${fontSizes.body2}px`,
            height: '30px',
          },
          sizeTiny: {
            padding: '2px 6px',
            fontSize: '0.75rem',
            minWidth: 'auto',
            lineHeight: 1.5,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: themePalette.palette.text.primary,
            '&:hover': {
              backgroundColor: themePalette.palette.bg.paperHover,
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontWeight: 600,
            variants: [
              {
                props: { variant: 'outlined' },
                style: {
                  padding: '3px 11px',
                  borderRadius: 120,
                  background: themePalette.palette.bg.paper,
                  borderColor: themePalette.palette.divider,

                  span: {
                    padding: 0,
                  },
                },
              },
              {
                props: { variant: 'filled' },
                style: {
                  borderRadius: 4,
                  color: themePalette.palette.txt.ui,
                  backgroundColor: themePalette.palette.bg.tertiary,
                  margin: 2,
                },
              },
            ],
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            defaultProps: {
              disableRipple: true,
            },
            borderRadius: 2,
            padding: '2px',
            background: themePalette.palette.bg.paper,
            '& .MuiToggleButton-root': {
              color: themePalette.palette.text.primary,
              backgroundColor: themePalette.palette.bg.paperHover,
              textTransform: 'none',
              padding: '8px 24px',
              '&.Mui-selected': {
                backgroundColor: themePalette.palette.primary.main,
                color: alpha(getContrastText(themePalette.palette.primary.main), 0.87),
                '&:hover': {
                  backgroundColor: themePalette.palette.primary.main,
                  color: alpha(getContrastText(themePalette.palette.primary.main), 0.87),
                },
              },
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: themePalette.palette.divider,
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            backgroundColor: themePalette.palette.bg.paperHover,
            borderRadius: 2,
            height: 6,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: themePalette.palette.bg.tooltip,
            color: themePalette.palette.txt.tooltip,
            fontWeight: 600,
            borderRadius: 8,
            backdropFilter: 'blur(13px)',
            py: '6px',
            px: '12px',
          },
          arrow: {
            color: themePalette.palette.bg.tooltip,
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            backgroundColor: themePalette.palette.bg.paper,
            border: 0,
            color: themePalette.palette.icon.default,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: themePalette.palette.icon.default,
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            color: themePalette.palette.text.primary,
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          disableUnderline: true,
          IconComponent: (props) => <Icon icon="chevron-down" {...props} style={{ top: 'auto' }} />,
        },
        styleOverrides: {
          root: {
            backgroundColor: themePalette.palette.bg.paperOpacity,
            borderRadius: 4,
            '&:hover': {
              backgroundColor: themePalette.palette.bg.tertiary,
            },
          },
          filled: {
            boxShadow: 'none',
            border: '1px solid',
            borderColor: themePalette.palette.divider,
            padding: '8px 12px',
            fontSize: fontSizes.body2,
            textTransform: 'uppercase',
            fontFamily: 'Aeonik Fono',
            lineHeight: '16px',
            color: themePalette.palette.txt.ui,
            borderRadius: 4,
          },
          icon: {
            color: themePalette.palette.icon.default,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: fontSizes.body2,
            color: themePalette.palette.txt.ui,
            textTransform: 'uppercase',
            fontFamily: 'Aeonik Fono !important',
            fontWeight: 500,
            '&:hover': {
              backgroundColor: themePalette.palette.bg.paperOpacity,
            },
            '&.Mui-selected': {
              backgroundColor: themePalette.palette.bg.paperOpacity,
            },
            '&.Mui-selected:hover': {
              backgroundColor: themePalette.palette.bg.tertiary,
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            backgroundColor: themePalette.palette.bg.paperOpacity,
            borderRadius: 4,
            fontSize: 12,
            color: themePalette.palette.txt.ui,
            border: '1px solid',
            borderColor: themePalette.palette.divider,
            '& .MuiInput-input': {
              padding: '4px !important',
            },
          },
          inputRoot: {
            padding: 4,
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            fontSize: 12,
            '&:before': {
              borderBottom: 'none',
            },
            '&:after': {
              borderBottom: 'none',
            },
            '&:hover:not(.Mui-disabled):before': {
              borderBottom: 'none',
            },
          },
        },
      },
      MuiSwitch: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            width: 20,
            height: 12,
            padding: 0,
            display: 'flex',
            boxShadow: 'none',
            '&:active': {
              '& .MuiSwitch-thumb': {
                width: 7,
              },
              '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(7px)',
              },
            },
          },
          switchBase: {
            padding: 3,
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '&.Mui-checked': {
              transform: 'translateX(8px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: themePalette.palette.primary.main,
              },
              '.MuiSwitch-thumb': {
                opacity: 1,
                backgroundColor: themePalette.palette.background.default,
              },
            },
          },
          thumb: ({ theme }: { theme: Theme }) => ({
            boxShadow: 'none',
            width: 6,
            height: 6,
            borderRadius: 6,
            transition: theme.transitions.create(['width'], { duration: 200 }),
            backgroundColor: theme.palette.bg.tertiary,
          }),
          track: ({ theme }: { theme: Theme }) => ({
            borderRadius: 8,
            opacity: 1,
            backgroundColor: theme.palette.icon.default,
            boxSizing: 'border-box',
          }),
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            backgroundColor: themePalette.palette.background.default,
            boxShadow: themePalette.palette.buttonShadow,
            '&:hover': {
              backgroundColor: darken(themePalette.palette.background.default, 0.05),
            },
            '&:focus-visible': {
              outline: `2px solid ${themePalette.palette.primary.main}`,
              outlineOffset: 2,
            },
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1536,
      },
    },
  });
};

export default getTheme;
