import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import Wrapper from '@components/wrapper';
import { Link } from 'react-router-dom';
import Icon from '@components/icon';
import { ThemeSwitcher } from '@components/theme-provider/theme-switcher';
import { UserInfoCard } from '@components/user-info-card';

const Header = (): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      component="header"
      sx={{
        bgcolor: 'bg.paper',
      }}
      borderBottom="1px solid"
      borderColor="divider"
    >
      <Wrapper style={{ border: 'none' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" py={1.5} px={3}>
          <Link
            to="/"
            style={{
              textDecoration: 'none',
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-start"
              sx={{
                pl: {
                  xs: '40px',
                  md: 0,
                },
              }}
              gap={1}
            >
              <Icon
                icon="logo-edge"
                color={theme.palette.brandColor}
                style={{
                  width: 40,
                  height: 40,
                  marginLeft: -40,
                }}
              />
              <Typography variant="label" fontSize={28} color="text.primary" display="inline">
                Edge
              </Typography>
            </Box>
          </Link>
          {isMobile ? (
            <Box display="flex" gap={2} alignItems="center">
              <Typography
                variant="link"
                component="a"
                href="https://chaoslabs.xyz/"
                target="_blank"
                rel="noreferrer"
                display="flex"
                alignItems="center"
                sx={{
                  columnGap: '4px',
                }}
              >
                Main Site
                <Icon icon="arrow-external" />
              </Typography>
              <UserInfoCard loginTooltipPlacement="bottom" />
            </Box>
          ) : (
            <Box display="flex" gap={2} alignItems="center">
              <Typography
                variant="link"
                component="a"
                href="https://chaoslabs.xyz/contact-us"
                target="_blank"
                rel="noreferrer"
                display="flex"
                alignItems="center"
              >
                Contact Us
              </Typography>
              <Typography
                variant="link"
                component="a"
                href="https://chaoslabs.xyz/"
                target="_blank"
                rel="noreferrer"
                display="flex"
                alignItems="center"
                sx={{
                  columnGap: '4px',
                }}
              >
                Chaos Labs Website
                <Icon icon="arrow-external" />
              </Typography>
              <ThemeSwitcher />
              <UserInfoCard loginTooltipPlacement="bottom" />
            </Box>
          )}
        </Box>
      </Wrapper>
    </Box>
  );
};

export default Header;
