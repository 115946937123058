import { ReactComponent as DefaultToken } from '@assets/crypto-icons/default.svg';
import { ReactComponent as AgentsAlt } from '@assets/icons/agents-alt.svg';
import { ReactComponent as Agents } from '@assets/icons/agents.svg';
import { ReactComponent as AnimatedCircle } from '@assets/icons/animated-circle.svg';
import { ReactComponent as ArrowDown } from '@assets/icons/arrow-down.svg';
import { ReactComponent as ArrowExternal } from '@assets/icons/arrow-external.svg';
import { ReactComponent as ArrowUpAlt } from '@assets/icons/arrow-up-alt.svg';
import { ReactComponent as ArrowUpRight } from '@assets/icons/arrow-up-right.svg';
import { ReactComponent as ArrowUp } from '@assets/icons/arrow-up.svg';
import { ReactComponent as BookmarkBorderIcon } from '@assets/icons/bookmark-border.svg';
import { ReactComponent as Bookmark } from '@assets/icons/bookmark.svg';
import { ReactComponent as Boxes } from '@assets/icons/boxes.svg';
import { ReactComponent as Chaos } from '@assets/icons/chaos.svg';
import { ReactComponent as CheckCircle } from '@assets/icons/check-circle.svg';
import { ReactComponent as CheckSquare } from '@assets/icons/check-square.svg';
import { ReactComponent as ChevronDown } from '@assets/icons/chevron-down.svg';
import { ReactComponent as ChevronLeft } from '@assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from '@assets/icons/chevron-right.svg';
import { ReactComponent as Circle } from '@assets/icons/circle.svg';
import { ReactComponent as Close } from '@assets/icons/close.svg';
import { ReactComponent as CopyIcon } from '@assets/icons/copy.svg';
import { ReactComponent as Council } from '@assets/icons/council.svg';
import { ReactComponent as DiscoverAlt } from '@assets/icons/discover-alt.svg';
import { ReactComponent as Done } from '@assets/icons/done.svg';
import { ReactComponent as Doughnut } from '@assets/icons/doughnut.svg';
import { ReactComponent as DrawerClose } from '@assets/icons/drawer-close.svg';
import { ReactComponent as DrawerOpen } from '@assets/icons/drawer-open.svg';
import { ReactComponent as ExitLeft } from '@assets/icons/exit-left.svg';
import { ReactComponent as ExportIcon } from '@assets/icons/export.svg';
import { ReactComponent as ExternalLink } from '@assets/icons/external-link.svg';
import { ReactComponent as FileUser } from '@assets/icons/file-user.svg';
import { ReactComponent as File } from '@assets/icons/file.svg';
import { ReactComponent as Filter } from '@assets/icons/filter.svg';
import { ReactComponent as FullScreen } from '@assets/icons/full-screen.svg';
import { ReactComponent as Globe } from '@assets/icons/globe.svg';
import { ReactComponent as HistoryAlt } from '@assets/icons/history-alt.svg';
import { ReactComponent as History } from '@assets/icons/history.svg';
import { ReactComponent as InfoCircle } from '@assets/icons/info-circle.svg';
import { ReactComponent as InfoIcon } from '@assets/icons/info.svg';
import { ReactComponent as IntersectCircle } from '@assets/icons/intersect-circle.svg';
import { ReactComponent as Keyboard } from '@assets/icons/keyboard.svg';
import { ReactComponent as Linkedin } from '@assets/icons/linkedin.svg';
import { ReactComponent as LogOut } from '@assets/icons/log-out.svg';

import { ReactComponent as ABC } from '@assets/icons/logo-abc.svg';
import { ReactComponent as LogoAP } from '@assets/icons/logo-ap.svg';
import { ReactComponent as ChaosLogoText } from '@assets/icons/logo-chaos-text.svg';
import { ReactComponent as LogoChaosLabs } from '@assets/icons/logo-chaoslabs.svg';
import { ReactComponent as LogoChatGPT } from '@assets/icons/logo-chatgpt.svg';
import { ReactComponent as LogoClaude } from '@assets/icons/logo-claude.svg';
import { ReactComponent as LogoCNN } from '@assets/icons/logo-cnn.svg';
import { ReactComponent as LogoDuckDuckGo } from '@assets/icons/logo-duckduckgo.svg';
import { ReactComponent as AnimatedEdge } from '@assets/icons/logo-edge-animated.svg';
import { ReactComponent as Edge } from '@assets/icons/logo-edge.svg';
import { ReactComponent as LogoFox } from '@assets/icons/logo-fox.svg';
import { ReactComponent as LogoGemini } from '@assets/icons/logo-gemini.svg';
import { ReactComponent as LogoGoogle } from '@assets/icons/logo-google.svg';
import { ReactComponent as LogoGrok } from '@assets/icons/logo-grok.svg';
import { ReactComponent as LogoLinkedIn } from '@assets/icons/logo-linkedin.svg';
import { ReactComponent as LogoMeta } from '@assets/icons/logo-meta.svg';
import { ReactComponent as LogoMistral } from '@assets/icons/logo-mistral.svg';
import { ReactComponent as LogoWikipedia } from '@assets/icons/logo-wikipedia.svg';
import { ReactComponent as LogoX } from '@assets/icons/logo-x.svg';
import { ReactComponent as Mail } from '@assets/icons/mail.svg';
import { ReactComponent as MinusSquareIcon } from '@assets/icons/minus-square.svg';
import { ReactComponent as MinusIcon } from '@assets/icons/minus.svg';
import { ReactComponent as PaperclipIcon } from '@assets/icons/paperclip.svg';
import { ReactComponent as PlusSquareIcon } from '@assets/icons/plus-square.svg';
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';
import { ReactComponent as Quote } from '@assets/icons/quote.svg';
import { ReactComponent as RefreshIcon } from '@assets/icons/refresh.svg';
import { ReactComponent as ReplayIcon } from '@assets/icons/replay.svg';
import { ReactComponent as SearchIcon } from '@assets/icons/search.svg';
import { ReactComponent as SettingsAlt } from '@assets/icons/settings-alt.svg';
import { ReactComponent as SettingsIcon } from '@assets/icons/settings.svg';
import { ReactComponent as SortAsc } from '@assets/icons/sort-asc.svg';
import { ReactComponent as SortDesc } from '@assets/icons/sort-desc.svg';
import { ReactComponent as SortNone } from '@assets/icons/sort-none.svg';
import { ReactComponent as ThemeMode } from '@assets/icons/theme-mode.svg';
import { ReactComponent as ToolsAlt } from '@assets/icons/tools-alt.svg';
import { ReactComponent as Tools } from '@assets/icons/tools.svg';
import { ReactComponent as TrashIcon } from '@assets/icons/trash.svg';
import { ReactComponent as Twitter } from '@assets/icons/twitter.svg';
import { ReactComponent as Users } from '@assets/icons/users.svg';
import { useTheme } from '@mui/material';
import React, { AllHTMLAttributes, FC } from 'react';

// TODO: Make these separate React component files so that using the Icon component doesn't load the entire icon set
const iconComponents = {
  agents: Agents,
  'agents-alt': AgentsAlt,
  'arrow-down': ArrowDown,
  'arrow-external': ArrowExternal,
  'arrow-up': ArrowUp,
  'arrow-up-alt': ArrowUpAlt,
  'arrow-up-right': ArrowUpRight,
  bookmark: Bookmark,
  'bookmark-border': BookmarkBorderIcon,
  boxes: Boxes,
  'check-circle': CheckCircle,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-down': ChevronDown,
  close: Close,
  council: Council,
  'tools-alt': ToolsAlt,
  'discover-alt': DiscoverAlt,
  doughnut: Doughnut,
  'drawer-close': DrawerClose,
  'drawer-open': DrawerOpen,
  'exit-left': ExitLeft,
  'external-link': ExternalLink,
  export: ExportIcon,
  file: File,
  'file-user': FileUser,
  filter: Filter,
  'full-screen': FullScreen,
  globe: Globe,
  history: History,
  'history-alt': HistoryAlt,
  'info-circle': InfoCircle,
  'intersect-circle': IntersectCircle,
  keyboard: Keyboard,
  linkedin: Linkedin,
  'log-out': LogOut,
  'logo-abc': ABC,
  'logo-ap': LogoAP,
  'logo-chaoslabs': LogoChaosLabs,
  'logo-chatgpt': LogoChatGPT,
  'logo-gemini': LogoGemini,
  'logo-claude': LogoClaude,
  'logo-cnn': LogoCNN,
  'logo-duckduckgo': LogoDuckDuckGo,
  'logo-edge': Edge,
  'logo-edge-animated': AnimatedEdge,
  'logo-chaos-text': ChaosLogoText,
  'logo-chaos': Chaos,
  'logo-chaos-animated': AnimatedEdge,
  'logo-fox': LogoFox,
  'logo-google': LogoGoogle,
  'logo-grok': LogoGrok,
  'logo-linkedin': LogoLinkedIn,
  'logo-meta': LogoMeta,
  'logo-mistral': LogoMistral,
  'logo-wikipedia': LogoWikipedia,
  'logo-x': LogoX,
  mail: Mail,
  quote: Quote,
  'settings-alt': SettingsAlt,
  settings: SettingsIcon,
  'sort-asc': SortAsc,
  'sort-desc': SortDesc,
  'sort-none': SortNone,
  'theme-mode': ThemeMode,
  tools: Tools,
  twitter: Twitter,
  users: Users,
  circle: Circle,
  copy: CopyIcon,
  paperclip: PaperclipIcon,
  trash: TrashIcon,
  plus: PlusIcon,
  'plus-square': PlusSquareIcon,
  'minus-square': MinusSquareIcon,
  minus: MinusIcon,
  refresh: RefreshIcon,
  search: SearchIcon,
  info: InfoIcon,
  'animated-circle': AnimatedCircle,
  replay: ReplayIcon,
  'check-square': CheckSquare,
  'default-token': DefaultToken,
  done: Done,
};

export type IconNames = keyof typeof iconComponents;

const getIcon = (iconName: IconNames, color?: string) => {
  const IconComponent = iconComponents[iconName];
  const theme = useTheme();
  // Icons should use CSS `currentColor` for SVG fill and stroke to inherit from text color
  return IconComponent ? <IconComponent color={color ?? theme.palette.text.primary} /> : <span />;
};

interface IconProps extends AllHTMLAttributes<HTMLImageElement> {
  icon: IconNames;
  color?: string;
  style?: React.CSSProperties;
}

const Icon: FC<IconProps> = ({ icon, color = 'inherit', ...rest }): JSX.Element =>
  React.cloneElement(getIcon(icon, color), {
    ...rest,
  });

export default Icon;
