import LayoutHeaderFooter from '@components/layout-header-footer';
import { Tab } from '@components/router-tabs';
import React from 'react';
import { InternalToolPaths } from '../types/routers';

const internalToolTabs: Tab[] = [
  {
    key: 'overview',
    label: 'OVERVIEW',
    to: InternalToolPaths.Overview,
  },
  {
    key: 'approvalHub',
    label: 'APPROVAL HUB',
    to: InternalToolPaths.ApprovalHub,
  },
  {
    key: 'recommendationSettings',
    label: 'RECOMMENDATION SETTINGS',
    to: InternalToolPaths.RecommendationsSettings,
  },
];

export const InternalToolLayout = () => <LayoutHeaderFooter tabs={internalToolTabs} />;
