import { styled } from '@mui/material';
import { SnackbarProvider as NotiStackProvider, MaterialDesignContent } from 'notistack';

type Props = {
  children: React.ReactNode;
};

const SuccessSnackbarStyle = styled(MaterialDesignContent)(({ theme }) => ({
  color: theme.palette.text.primary,
  '&.notistack-MuiContent-success': {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid',
    borderColor: theme.palette.success.main,
  },
}));

const ErrorSnackbarStyle = styled(MaterialDesignContent)(({ theme }) => ({
  color: theme.palette.error.main,
  '&.notistack-MuiContent-error': {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid',
    borderColor: theme.palette.error.main,
  },
}));

const SnackbarProvider = ({ children }: Props) => (
  <NotiStackProvider
    Components={{
      success: SuccessSnackbarStyle,
      error: ErrorSnackbarStyle,
    }}
    maxSnack={10}
    autoHideDuration={2000}
  >
    {children}
  </NotiStackProvider>
);

export default SnackbarProvider;
